import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ProductCard from '../components/common/ProductCard'
import MainContainer from "../components/containers/MainContainer"
import HeadDataNew from "../data/HeadDataNew"
import YoutubePlayerLite from '../components/common/YoutubePlayerLite'

const buttonStyle = "w-full bg-red-900 hover:bg-red-800 font-display text-white text-lg xl:text-2xl uppercase font-bold py-3 px-6 mx-0 mb-3 xl:mb-0 rounded tracking-widest shadow"

const slugify = require('@sindresorhus/slugify')

export default function categoryTemplate({ data }) {
  const product = data.prismicProduct
  const allProducts = data.allPrismicProduct.edges
  const settings = data.prismicSettings
  const productCat = data.prismicProductCategoriesPages
  const powerTransfersImage = getImage(settings.data.power_transfers_bottom_section_image)

  return (
    <MainContainer>
      {product.data.category === "Command Packages"
        ?
          <HeadDataNew
            title="Command Packages"
            description ={
              productCat.data.product_description_first
              ?
              productCat.data.product_description_first
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Cylindrical Locks"
        ?
          <HeadDataNew
            title="Cylindrical Locks"
            description ={
              productCat.data.product_description_second
              ?
              productCat.data.product_description_second
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Exit Devices"
        ?
          <HeadDataNew
            title="Exit Devices"
            description ={
              productCat.data.product_description_third
              ?
              productCat.data.product_description_third
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Power Supplies"
        ?
          <HeadDataNew
            title="Power Supplies"
            description ={
              productCat.data.product_description_fourth
              ?
              productCat.data.product_description_fourth
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Accessories"
        ?
          <HeadDataNew
            title="Accessories"
            description ={
              productCat.data.product_description_fifth
              ?
              productCat.data.product_description_fifth
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Mortise Locks"
        ?
          <HeadDataNew
            title="Mortise Locks"
            description ={
              productCat.data.product_description_sixth
              ?
              productCat.data.product_description_sixth
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Exit Device Kits"
        ?
          <HeadDataNew
            title="Exit Device Kits"
            description ={
              productCat.data.product_description_seventh
              ?
              productCat.data.product_description_seventh
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Exit Trims"
        ?
          <HeadDataNew
            title="Exit Trims"
            description ={
              productCat.data.product_description_eighth
              ?
              productCat.data.product_description_eighth
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Power Transfers"
        ?
          <HeadDataNew
            title="Power Transfers"
            description ={
              productCat.data.product_description_ninth
              ?
              productCat.data.product_description_ninth
              :
              null
            }
          />
        :
          null
      }

      {product.data.category === "Low Energy Operator"
        ?
          <HeadDataNew
            title="Low Energy Operator"
            description ={
              productCat.data.product_description_tenth
              ?
              productCat.data.product_description_tenth
              :
              null
            }
          />
        :
          null
      }

      <div className='bg-gray-100'>
        <div className='py-2 md:py-6'>
          <div className='w-11/12 xl:max-w-screen-xl flex flex-row items-center justify-between mx-auto'>
            <div className='mb-0'>
              <div className='text-gray-600 text-xs md:text-sm'><Link to='/' className='font-semibold hover:underline'>Home</Link> &raquo; {product.data.category ? product.data.category : null}</div>
            </div>
          </div>
        </div>

        <div className='w-11/12 xl:max-w-screen-xl bg-gray-100 flex flex-col items-center justify-center border-b border-red-200 pt-6 pb-5 mb-0 mx-auto'>
          <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>{product.data.category ? product.data.category : null}</h1>

          {product.data.category === "Command Packages"
            ?
              productCat.data.product_description_first
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_first}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Cylindrical Locks"
            ?
              productCat.data.product_description_second
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_second}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Exit Devices"
            ?
              productCat.data.product_description_third
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_third}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Power Supplies"
            ?
              productCat.data.product_description_fourth
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_fourth}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Accessories"
            ?
              productCat.data.product_description_fifth
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_fifth}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Mortise Locks"
            ?
              productCat.data.product_description_sixth
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_sixth}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Exit Device Kits"
            ?
              productCat.data.product_description_seventh
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_seventh}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Exit Trims"
            ?
              productCat.data.product_description_eighth
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_eighth}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Power Transfers"
            ?
              productCat.data.product_description_ninth
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_ninth}</p>
              :
              null
            :
              null
          }

          {product.data.category === "Low Energy Operator"
            ?
              productCat.data.product_description_tenth
              ?
              <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{productCat.data.product_description_tenth}</p>
              :
              null
            :
              null
          }
        </div>

        <div className='bg-gray-100 pt-8 pb-12'>
          <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mx-auto'>
            {allProducts.map( allProduct => {
              const imageData = getImage(allProduct.node.data.product_image)
              return (
                <>
                  {allProduct.node.data.category === product.data.category
                    ?
                    <ProductCard
                      linkTo={allProduct.node.data.category && allProduct.node.data.product_name ? `/${slugify(allProduct.node.data.category)}/${allProduct.node.uid}` : '/' }
                      imageData={imageData ? imageData : null}
                      altText={allProduct.node.data.product_name ? `Command Access ${allProduct.node.data.product_name}` : ''}
                      prodName={allProduct.node.data.product_name ? allProduct.node.data.product_name : 'Product Name'}
                      prodSub1={allProduct.node.data.product_brand ? allProduct.node.data.product_brand : 'Product Description'}
                      prodSub2={allProduct.node.data.product_type ? allProduct.node.data.product_type : 'Product Type'}
                      withBadge={allProduct.node.data.motor_driven ? true : false}
                    />
                    :
                    null
                  }
                </>
              )
            })}
          </div>

          {product.data.category === 'Exit Device Kits'
            ?
              <div className='w-11/12 xl:max-w-screen-xl flex justify-center mx-auto border-t border-red-200 pt-6 mt-8'>
                <a href={settings.data.exit_device_kit_bottom_section_button_link ? settings.data.exit_device_kit_bottom_section_button_link.url : 'https://www.commandaccess.com'} target='_blank' rel='noopener noreferrer'>
                  <button className="bg-red-900 hover:bg-red-800 font-display text-white text-2xl uppercase font-bold py-3 px-6 rounded tracking-widest shadow">
                    {settings.data.exit_device_kit_bottom_section_button_text ? settings.data.exit_device_kit_bottom_section_button_text : 'Button Text'}
                  </button>
                </a>
              </div>
            :
              null
          }

          {product.data.category === 'Power Supplies'
            ?
              <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center justify-center mx-auto border-t border-red-200 pt-8 mt-8'>
                <div className='w-full md:w-8/12 lg:w-6/12 mb-6 shadow-md'>
                  <YoutubePlayerLite youtubeID={settings.data.power_supplies_bottom_section_video_id} noLight />
                </div>

                <p className='text-gray-800 mb-4'>{settings.data.power_supplies_bottom_section_text ? settings.data.power_supplies_bottom_section_text : 'Insert text here.'}</p>
                <a href={settings.data.power_supplies_bottom_section_button_link ? settings.data.power_supplies_bottom_section_button_link.url : 'https://www.commandaccess.com'} target='_blank' rel='noopener noreferrer'>
                  <button className="bg-red-900 hover:bg-red-800 font-display text-white text-2xl uppercase font-bold py-3 px-6 rounded tracking-widest shadow">
                    {settings.data.power_supplies_bottom_section_button_text ? settings.data.power_supplies_bottom_section_button_text : 'Button Text'}
                  </button>
                </a>
              </div>
            :
              null
          }

          {product.data.category === 'Power Transfers'
            ?
              <>
                <div className='w-11/12 xl:max-w-screen-xl flex flex-col lg:flex-row md:items-center justify-center mx-auto border-t border-red-200 pt-6 mt-8'>
                  <a href={settings.data.power_transfers_bottom_section_button_one_link ? settings.data.power_transfers_bottom_section_button_one_link.url : 'https://www.commandaccess.com'} target='_blank' rel='noopener noreferrer' className='lg:mx-3'>
                    <button className={buttonStyle}>
                      {settings.data.power_transfers_bottom_section_button_one_text ? settings.data.power_transfers_bottom_section_button_one_text : 'Button Text'}
                    </button>
                  </a>

                  <a href={settings.data.power_transfers_bottom_section_button_two_link ? settings.data.power_transfers_bottom_section_button_two_link.url : 'https://www.commandaccess.com'} target='_blank' rel='noopener noreferrer' className='lg:mx-3'>
                    <button className={buttonStyle}>
                      {settings.data.power_transfers_bottom_section_button_two_text ? settings.data.power_transfers_bottom_section_button_two_text : 'Button Text'}
                    </button>
                  </a>

                  <a href={settings.data.power_transfers_bottom_section_button_three_link ? settings.data.power_transfers_bottom_section_button_three_link.url : 'https://www.commandaccess.com'} target='_blank' rel='noopener noreferrer' className='lg:mx-3'>
                    <button className={buttonStyle}>
                      {settings.data.power_transfers_bottom_section_button_three_text ? settings.data.power_transfers_bottom_section_button_three_text : 'Button Text'}
                    </button>
                  </a>
                </div>

                <div className='w-11/12 lg:w-8/12 xl:max-w-screen-md flex flex-col md:flex-row items-center justify-center mx-auto mt-8'>
                  <GatsbyImage
                    image={powerTransfersImage ? powerTransfersImage : null}
                    alt=""
                    className='w-full md:w-2/3 mb-6 md:mb-0 mx-auto rounded shadow'
                  />

                  <div className='ml-0 md:ml-6'>
                    <h2 className='text-3xl text-red-900 leading-tight tracking-wide'>{settings.data.power_transfers_bottom_section_title ? settings.data.power_transfers_bottom_section_title : null}</h2>
                    <p className='text-base font-medium text-gray-800'>{settings.data.power_transfers_bottom_section_text ? settings.data.power_transfers_bottom_section_text : null}</p>
                  </div>
                </div>
              </>
            :
              null
          }
        </div>
      </div>
    </MainContainer>
  )
}

export const categoryTemplateQueryNew = graphql`
  query($id: String!) {
    prismicProduct(id: { eq: $id }) {
      id
      uid
      data {
        category
      }
    }
    allPrismicProduct(sort: {fields: data___product_name, order: ASC}) {
      edges {
        node {
          uid
          data {
            product_name
            product_brand
            product_type
            category
            motor_driven
            product_image {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
    }
    prismicSettings {
      id
      data {
        exit_device_kit_bottom_section_button_link {
          url
        }
        exit_device_kit_bottom_section_button_text
        power_supplies_bottom_section_button_link {
          url
        }
        power_supplies_bottom_section_button_text
        power_supplies_bottom_section_text
        power_supplies_bottom_section_video_id
        power_transfers_bottom_section_button_one_link {
          url
        }
        power_transfers_bottom_section_button_one_text
        power_transfers_bottom_section_button_three_link {
          url
        }
        power_transfers_bottom_section_button_three_text
        power_transfers_bottom_section_button_two_link {
          url
        }
        power_transfers_bottom_section_button_two_text
        power_transfers_bottom_section_text
        power_transfers_bottom_section_title
        power_transfers_bottom_section_image {
          gatsbyImageData(
            width: 500
          )
        }
      }
    }
    prismicProductCategoriesPages {
      id
      data {
        product_description_first
        product_description_second
        product_description_third
        product_description_fourth
        product_description_fifth
        product_description_sixth
        product_description_seventh
        product_description_eighth
        product_description_ninth
        product_description_tenth
      }
    }
  }
`